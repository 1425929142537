<template>
  <authenticated-layout>
    <div class="mb-5">
      <v-img
        src="https://duesseldorf-eyewear.takeitdigital.de/media/ddeye/logo.svg"
        style="max-width: 150px"></v-img>
    </div>

    <v-card>
      <v-card-title>Dashboard</v-card-title>
      <v-card-text>
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>

        <v-calendar
          ref="calendar"
          v-model="calendar.value"
          :weekdays="calendar.weekday"
          :type="calendar.type"></v-calendar>
      </v-card-text>
    </v-card>
  </authenticated-layout>
</template>

<script>
import AuthenticatedLayout from '@/components/Layouts/Authenticated.vue' // @ is an alias to /src

export default {
  name: 'Home.vue',

  components: {
    AuthenticatedLayout
  },

  data: () => ({
    calendar: {
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      value: '',
      weekday: [0, 1, 2, 3, 4, 5, 6],
      items: []
    }
  }),

  methods: {
    loadCalendar: function () {
      this.$http.get('/calendar')
        .then(response => {
          this.calendar.items = response.data
        })
    }
  },

  mounted () {
    this.loadCalendar()
  }
}
</script>
