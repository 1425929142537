import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import AussendienstCustomerIndex from '../views/Aussendienst/Customers/Index.vue'
import AussendienstCustomerShow from '../views/Aussendienst/Customers/Show.vue'
import ComingSoon from '../views/ComingSoon.vue'

import InnendienstOrdersIndex from '../views/Innendienst/Orders/Index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/coming-soon',
    name: 'coming_soon',
    component: ComingSoon,
    meta: {
      auth: true
    }
  },
  {
    path: '/aussendienst/customers',
    component: AussendienstCustomerIndex,
    name: 'aussendienst.customers.index'
  },
  {
    path: '/aussendienst/customers/:slug',
    component: AussendienstCustomerShow,
    name: 'aussendienst.customers.show'
  },
  {
    path: '/innendienst/orders',
    component: InnendienstOrdersIndex,
    name: 'innendienst.orders.index'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('ddeye-user')

  if (loggedIn) {
    store.dispatch('loadUserData')
  }

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }

  next()
})

export default router
