import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'http://localhost:8000/api/v2/'
// axios.defaults.baseURL = 'https://duesseldorf-eyewear.takeitdigital.de/api/v2/'

export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('ddeye-user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('ddeye-user')
      location.reload()
    }
  },

  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('/login', credentials)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },

    loadUserData ({ commit }) {
      const slug = JSON.parse(<string>localStorage.getItem('ddeye-user')).data.slug

      axios.get(`/user/${slug}`)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },

    logout ({ commit }) {
      commit('clearUserData')
    }
  },
  getters: {
    isLogged: state => !!state.user
  },
  modules: {}
})
