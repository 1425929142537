<template>
  <authenticated-layout :loadingOverlay="customerTable.loading">
    <v-card>
      <v-card-title>
        Kunden
        <v-spacer></v-spacer>
        <v-text-field
          v-model="customerTable.search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="customerTableHeaders"
        :items="customers"
        :loading="customerTable.loading"
        :loading-text="customerTable.loadingText"
        :search="customerTable.search"
        @click:row="showCustomer"
        dense
        class="elevation-1"></v-data-table>
    </v-card>
  </authenticated-layout>
</template>

<script>
import AuthenticatedLayout from '@/components/Layouts/Authenticated'

export default {
  name: 'CustomerIndex.vue',

  components: { AuthenticatedLayout },

  data: () => ({
    customerTable: {
      loading: false,
      loadingText: 'Lade Kunden... Bitte warten',
      search: ''
    },
    customerTableHeaders: [
      {
        text: 'Kundentyp',
        align: 'start',
        sortable: false,
        value: 'customer_type'
      },
      {
        text: 'Kundennummer',
        sortable: true,
        value: 'customer_number'
      },
      {
        text: 'Distributoren-ID',
        sortable: true,
        value: 'custom_customer_number'
      },
      {
        text: 'Name',
        sortable: true,
        value: 'company_name'
      },
      {
        text: 'Anschrift',
        value: ''
      },
      {
        text: 'Team',
        value: 'team.name'
      }
    ],
    customers: []
  }),

  methods: {
    loadCustomers: function () {
      this.customerTable.loading = true

      this.$http.get('/customers').then(response => {
        this.customers = response.data
        this.customerTable.loading = false
      })
    },

    showCustomer: function (row) {
      this.$router.push({ name: 'aussendienst.customers.show', params: { slug: row.slug } })
    }
  },

  mounted () {
    this.loadCustomers()
  }
}
</script>

<style scoped>

</style>
