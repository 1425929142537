<template>
  <div>Distributor</div>
</template>

<script>
export default {
  name: 'DistributorTab'
}
</script>

<style scoped>

</style>
