<template>
  <v-row>
    <v-col>
      <v-card flat>
        <v-card-title>Zahlungsart</v-card-title>

        <v-form v-model="valid" class="pl-10 pb-10" v-if="!loading">
          <v-select
            :items="paymentMethods"
            v-model="fields.payment_type"
            label="Zahlungsmethoden"
            item-text="value"
            item-value="key"></v-select>

          <div v-if="fields.payment_type == 'sepa0' || fields.payment_type == 'sepa1' || fields.payment_type == 'sepa2' || fields.payment_type == 'sepa3' || fields.payment_type == 'sepa4'">
            <v-text-field
              v-model.trim.lazy="fields.bank_iban"
              label="IBAN"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="fields.bank_bic"
              label="BIC"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="fields.bank_name"
              label="Bank"
            ></v-text-field>
            <v-text-field
              v-model.trim.lazy="fields.bank_owner"
              label="Bankinhaber (wenn leer, wird Geschäftsname verwendet)"
            ></v-text-field>
          </div>

          <v-btn block color="success" @click.prevent="updateCustomer">Speichern</v-btn>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CustomerPaymentMethodTabComponent',

  data: () => ({
    loading: false,
    valid: true,
    paymentMethods: [
      { key: 'rechnung', value: 'Rechnung' },
      { key: 'sepa0', value: 'SEPA-Lastschrift (0% Skonto)' },
      { key: 'sepa2', value: 'SEPA-Lastschrift (2% Skonto)' },
      { key: 'sepa3', value: 'SEPA-Lastschrift (3% Skonto)' },
      { key: 'sepa4', value: 'SEPA-Lastschrift (4% Skonto)' },
      { key: 'vorkasse', value: 'Vorkasse' },
      { key: 'nachnahme', value: 'Per Nachnahme' },
      { key: 'ratenzahlung', value: 'Ratenzahlung' }
    ],
    fields: {
      payment_type: 'invoice',
      bank_owner: '',
      bank_iban: '',
      bank_bic: '',
      bank_name: ''
    },
    ibanInvalid: false,
    saved: false
  }),

  watch: {
    'fields.bank_iban': function (val) {
      this.checkIban(val)
    }
  },

  methods: {
    updateCustomer: function () {
      return true
    },
    checkIban: function (e) {
      if (this.fields.bank_iban.length === 0) {
        return
      }

      this.$http.post('/check-iban', {
        iban: this.fields.bank_iban
      }).then(response => {
        if (response.data.error === undefined) {
          this.ibanInvalid = false
          this.fields.bank_bic = response.data.bank_bic
          this.fields.bank_name = response.data.bank_name
        } else {
          this.fields.bank_bic = ''
          this.fields.bank_name = ''
          this.ibanInvalid = true
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
