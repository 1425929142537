<template>
  <authenticated-layout :loadingOverlay="loading">
    <v-card v-if="!loading">
      <v-toolbar
        flat
        dark
        color="blue-grey darken-4">
        <v-toolbar-title>#{{ customer.customer_number }} - {{ customer.company_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            v-model="tab"
            fixed-tabs
            align-with-title>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              v-for="item in tabItems"
              :key="item.key"
              :href="'#tab-' + item.key">
              {{ item.label }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in tabItems"
          :key="index"
          :value="'tab-' + item.key">
          <v-card flat>
            <component v-bind:is="item.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </authenticated-layout>
</template>

<script>
import AuthenticatedLayout from '@/components/Layouts/Authenticated'

import CustomerGeneralTabComponent from '@/components/Customers/CustomerGeneralTabComponent'
import CustomerDocumentsTabComponent from '@/components/Customers/CustomerDocumentsTabComponent'
import CustomerAddressesTabComponent from '@/components/Customers/CustomerAddressesTabComponent'
import CustomerOrdersTabComponent from '@/components/Customers/CustomerOrdersTabComponent'
import CustomerPaymentMethodTabComponent from '@/components/Customers/CustomerPaymentMethodTabComponent'
import CustomerNotesTabComponent from '@/components/Customers/CustomerNotesTabComponent'

export default {
  name: 'CustomerShow.vue',

  components: {
    AuthenticatedLayout,
    CustomerGeneralTabComponent,
    CustomerAddressesTabComponent,
    CustomerOrdersTabComponent,
    CustomerPaymentMethodTabComponent,
    CustomerDocumentsTabComponent,
    CustomerNotesTabComponent
  },

  data: () => ({
    loading: false,
    customer: {},
    tab: null,
    tabItems: [
      { label: 'Allgemein', key: 'general', component: 'CustomerGeneralTabComponent' },
      { label: 'Adressen', key: 'locations', component: 'CustomerAddressesTabComponent' },
      { label: 'Zahlungsart', key: 'payment', component: 'CustomerPaymentMethodTabComponent' },
      { label: 'Dokumente', key: 'documents', component: 'CustomerDocumentsTabComponent' },
      { label: 'Bestellungen', key: 'orders', component: 'CustomerOrdersTabComponent' },
      { label: 'Notizen', key: 'notes', component: 'CustomerNotesTabComponent' }
    ]
  }),

  methods: {
    load: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.get(`customers/${customerSlug}`)
        .then(response => {
          this.customer = response.data
          this.loading = false
        })
    }
  },

  mounted () {
    this.load()
  }
}
</script>

<style scoped>

</style>
