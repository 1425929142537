<template>
  <authenticated-layout>
    <v-card>
      <v-card-title>
        Bestellungen
        <v-spacer></v-spacer>
        <v-text-field
          v-model="orderTable.search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="orderTableHeaders"
        :items="orders"
        :loading="orderTable.loading"
        :loading-text="orderTable.loadingText"
        :search="orderTable.search"
        dense
        class="elevation-1"></v-data-table>
    </v-card>
  </authenticated-layout>
</template>

<script>
import AuthenticatedLayout from '@/components/Layouts/Authenticated'

export default {
  name: 'OrderIndex.vue',

  components: { AuthenticatedLayout },

  data: () => ({
    orderTable: {
      loading: false,
      loadingText: 'Lade Bestellungen... Bitte warten',
      search: ''
    },
    orderTableHeaders: [
      {
        text: 'Bestellzeitpunkt',
        align: 'start',
        sortable: true,
        value: 'created_at'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status.name'
      },
      {
        text: 'Kunde',
        align: 'start',
        sortable: true,
        value: 'customer.company_name'
      },
      {
        text: 'Rechnungsadresse',
        align: 'start',
        sortable: true,
        value: ''
      },
      {
        text: 'Lieferadresse',
        align: 'start',
        sortable: true,
        value: ''
      },
      {
        text: 'Rechnungsbetrag',
        align: 'start',
        sortable: true,
        value: 'processed_data.summary.sums.invoiceAmount'
      },
      {
        text: 'Währung',
        align: 'start',
        sortable: true,
        value: 'processed_data.summary.currency'
      }
    ],
    orders: []
  }),

  methods: {
    loadOrders: function () {
      this.orderTable.loading = true

      this.$http.get('/orders').then(response => {
        this.orders = response.data
        this.orderTable.loading = false
      })
    }

    // showCustomer: function (row) {
    //   this.$router.push({ name: 'aussendienst.customers.show', params: { slug: row.slug } })
    // }
  },

  mounted () {
    this.loadOrders()
  }
}
</script>

<style scoped>

</style>
