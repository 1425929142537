<template>
  <div>
    failed to load
  </div>
</template>

<script>
export default {
  name: 'CustomerNotesTabComponent',

  data: () => ({
    notes: []
  }),

  methods: {
    loadNotes () {
      return true
    }
  },

  mounted () {
    this.loadNotes()
  }
}
</script>
