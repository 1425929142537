<template>
  <v-row>
    <v-col>
      <v-card flat>
        <v-card-title>Allgemein</v-card-title>
        <v-card-text>
          <v-tabs vertical>
            <v-tab
              v-for="(tabItem, index) in tabs.tabItems"
              :key="index"
              style="justify-content: flex-start"
              >
              <v-icon left>{{ tabItem.icon }}</v-icon>
              {{ tabItem.label }}
            </v-tab>

            <v-tab-item
              v-for="(tabItem, index) in tabs.tabItems"
              :key="index">
              <v-card flat>
                <component v-bind:is="tabItem.component"></component>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTab from './CustomersGeneralTab/DataTab'
import CorporateTab from './CustomersGeneralTab/CorporateTab'
import DiscountTab from './CustomersGeneralTab/DiscountTab'
import DistributorTab from './CustomersGeneralTab/DistributorTab'
import TeamTab from './CustomersGeneralTab/TeamTab'

export default {
  name: 'CustomerGeneralTabComponent',

  components: {
    DataTab
  },

  data: () => ({
    tabs: {
      tabItems: [
        { label: 'Daten', icon: 'mdi-clipboard-list-outline', component: DataTab },
        { label: 'Firmierung', icon: 'mdi-home-city-outline', component: CorporateTab },
        { label: 'Team', icon: 'mdi-account-group-outline', component: TeamTab },
        { label: 'Distributor', icon: 'mdi-account-star', component: DistributorTab },
        { label: 'Rabatte', icon: 'mdi-sale', component: DiscountTab }
      ]
    }
  }),

  created () {
    console.log('mounted')
  }
}
</script>

<style scoped>

</style>
