<template>
  <v-form v-model="valid" class="pl-10" v-if="!loading">
    <v-snackbar
      v-model="notification.state"
      color="green"
      :timeout="notification.timeout">Kunde erfolgreich gespeichert.</v-snackbar>

    <v-select
      :items="teams"
      v-model="customer.team_id"
      item-text="name"
      item-value="id"
      label="Team"></v-select>

    <v-select
      :items="selectableUsers"
      v-model="customer.user_id"
      item-text="name"
      item-value="id"
      label="Mitarbeiter"></v-select>

    <v-btn block color="success" @click.prevent="updateCustomer">Speichern</v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'Team',

  data: () => ({
    valid: true,
    loading: false,
    notification: {
      status: false,
      timeout: 2500
    },
    customer: {},
    teams: []
  }),

  computed: {
    selectableUsers: function () {
      return this.teams.find(team => team.id === this.customer.team_id).users
    }
  },

  methods: {
    loadCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.get(`customers/${customerSlug}`)
        .then(response => {
          this.customer = response.data
          this.loading = false
        })
    },
    updateCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.patch(`customers/${customerSlug}`, {
        team_id: this.customer.team_id,
        user_id: this.customer.user_id
      }).then(response => {
        this.loading = false
        this.notification.state = true
      })
    },
    loadTeams: function () {
      this.$http.get('teams')
        .then(response => {
          this.teams = response.data
        })
    }
  },

  mounted () {
    this.loadCustomer()
    this.loadTeams()
  }
}
</script>

<style scoped>

</style>
