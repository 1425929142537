import { render, staticRenderFns } from "./CustomerOrdersTabComponent.vue?vue&type=template&id=7949cb56&scoped=true&"
import script from "./CustomerOrdersTabComponent.vue?vue&type=script&lang=js&"
export * from "./CustomerOrdersTabComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7949cb56",
  null
  
)

export default component.exports