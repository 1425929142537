<template>
  <v-form v-model="valid" class="pl-10">
    <v-snackbar
      v-model="notification.state"
      color="green"
      :timeout="notification.timeout">Kunde erfolgreich gespeichert.</v-snackbar>

    <v-text-field
      v-model="customer.company_name"
      label="Firmenbezeichnung"
      required
    ></v-text-field>

    <v-btn block color="success" @click.prevent="updateCustomer">Speichern</v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'CorporateTab',

  data: () => ({
    valid: true,
    loading: false,
    customer: {},
    notification: {
      state: false,
      timeout: 2500
    }
  }),

  methods: {
    loadCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.get(`customers/${customerSlug}`)
        .then(response => {
          this.customer = response.data
          this.loading = false
        })
    },
    updateCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.patch(`customers/${customerSlug}`, this.customerUpdateData).then(response => {
        this.loading = false
        this.notification.state = true
      })
    }
  },

  mounted () {
    this.loadCustomer()
  }
}
</script>

<style scoped>

</style>
