<template>
  <authenticated-layout>
    Coming soon.
  </authenticated-layout>
</template>

<script>
import AuthenticatedLayout from '@/components/Layouts/Authenticated'

export default {
  name: 'coming_soon',

  components: { AuthenticatedLayout }
}
</script>

<style scoped>

</style>
