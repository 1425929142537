<template>
  <v-row>
    <v-col>
      <v-card flat>
        <v-card-title>Adressen</v-card-title>

        <v-data-table
          :headers="addressesTableHeaders"
          :items="addresses"
          :loading="addressesTable.loading"
          :loading-text="addressesTable.loadingText"
          :search="addressesTable.search"
          dense
          class="elevation-1"></v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CustomerAddressesTabComponent',

  data: () => ({
    addresses: [],
    addressesTable: {
      loading: false,
      loadingText: 'Lade Adressen... Bitte warten',
      search: ''
    },
    addressesTableHeaders: [
      {
        text: 'Adresstyp',
        align: 'start',
        sortable: false,
        value: 'location_type'
      },
      {
        text: 'Straße',
        align: 'start',
        sortable: false,
        value: 'location_street'
      },
      {
        text: 'PLZ',
        align: 'start',
        sortable: false,
        value: 'location_zip'
      },
      {
        text: 'Stadt',
        align: 'start',
        sortable: false,
        value: 'location_city'
      },
      {
        text: 'Land',
        align: 'start',
        sortable: false,
        value: 'location_country'
      },
      {
        text: 'Storefinder',
        align: 'start',
        sortable: false,
        value: 'is_storefiner'
      },
      {
        text: 'Freitext',
        align: 'start',
        sortable: false,
        value: 'free_text'
      }
    ]
  }),

  methods: {
    loadAddresses: function () {
      this.loading = true

      const customerSlug = this.$route.params.slug
      this.$http.get(`/customers/${customerSlug}/locations`)
        .then(response => {
          this.addresses = response.data
          this.loading = false
        })
    }
  },

  mounted () {
    this.loadAddresses()
  }
}
</script>

<style scoped>

</style>
