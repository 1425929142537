<template>
  <div>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="navbar.state = !navbar.state"></v-app-bar-nav-icon>
      <v-toolbar-title>Duesseldorf Eyewear</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      app
      class="blue-grey darken-4"
      v-model="navbar.state"
      dark>
      <v-sheet
        class="pa-4"
      >
        <div>{{ $store.state.user.data.name }}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list
        dense
        v-for="(navbarGroup, index) in navbar.groups"
        :key="index">
        <v-subheader>{{ navbarGroup.label }}</v-subheader>
        <v-list-item-group>
          <v-list-item
            v-for="(item, key) in navbarGroup.items"
            :key="key"
            :to="{ name: item.route }"
            link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="red">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container
        class="py-8 px-6"
        fluid>
        <slot></slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'Authenticated.vue',
  props: {
    loadingOverlay: {
      default: false
    }
  },

  data: () => ({

    navbar: {
      state: true,

      groups: [
        {
          label: 'Allgemein',
          items: [
            {
              label: 'Dashboard',
              icon: 'mdi-view-dashboard',
              route: 'Home'
            },
            {
              label: 'Sales-Ansicht',
              icon: 'mdi-home-circle-outline',
              route: 'coming_soon'
            }
          ]
        },
        {
          label: 'Außendienst',
          role: 'aussendienst',
          items: [
            {
              label: 'Kunden',
              icon: 'mdi-account-multiple',
              route: 'aussendienst.customers.index'
            },
            {
              label: 'Stock',
              icon: 'mdi-database',
              route: 'coming_soon'
            },
            {
              label: 'Reports',
              icon: 'mdi-chart-box-outline',
              route: 'coming_soon'
            }
          ]
        },
        {
          label: 'Teamleiter',
          role: 'teamleiter',
          items: [
            {
              label: 'Kunden',
              icon: 'mdi-account-multiple',
              route: 'aussendienst.customers.index'
            },
            {
              label: 'Stock',
              icon: 'mdi-database',
              route: 'coming_soon'
            }
          ]
        },
        {
          label: 'Innendienst',
          role: 'innendienst',
          items: [
            {
              label: 'Produkte',
              icon: 'mdi-package',
              route: 'coming_soon'
            },
            {
              label: 'Bestellungen',
              icon: 'mdi-clipboard-list-outline',
              route: 'innendienst.orders.index'
            },
            {
              label: 'Kunden',
              icon: 'mdi-account-multiple',
              route: 'coming_soon'
            },
            {
              label: 'Stock',
              icon: 'mdi-database',
              route: 'coming_soon'
            },
            {
              label: 'Standorte',
              icon: 'mdi-map-marker',
              route: 'coming_soon'
            },
            {
              label: 'News',
              icon: 'mdi-information-outline',
              route: 'coming_soon'
            },
            {
              label: 'Kalender',
              icon: 'mdi-calendar',
              route: 'coming_soon'
            }
          ]
        },
        {
          label: 'Management',
          role: 'management',
          items: [
            {
              label: 'Kunden',
              icon: 'mdi-account-multiple',
              route: 'coming_soon'
            },
            {
              label: 'Teams & Benutzer',
              icon: 'mdi-account-key',
              route: 'coming_soon'
            },
            {
              label: 'Finance',
              icon: 'mdi-currency-eur',
              route: 'coming_soon'
            },
            {
              label: 'Stock',
              icon: 'mdi-database',
              route: 'coming_soon'
            },
            {
              label: 'Logs',
              icon: 'mdi-post-outline',
              route: 'coming_soon'
            },
            {
              label: 'Länder & Währungen',
              icon: 'mdi-cog',
              route: 'coming_soon'
            },
            {
              label: 'Lagerbestand-Einstellungen',
              icon: 'mdi-cog',
              route: 'coming_soon'
            }
          ]
        }
      ]
    }
  })
}
</script>

<style scoped>

</style>
