<template>
  <v-form v-model="valid" class="pl-10">
    <v-snackbar
      v-model="notification.state"
      color="green"
      :timeout="notification.timeout">Kunde erfolgreich gespeichert.</v-snackbar>

    <v-text-field
      v-model="customer.company_vat"
      label="VAT/Umsatzsteuer-ID"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.customer_number"
      label="Kundennummer"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.custom_customer_number"
      label="Freitext-Kundennummer"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.phone"
      label="Telefon"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.email"
      label="Email-Adresse"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.homepage"
      label="Homepage"
      required
    ></v-text-field>
    <v-text-field
      v-model="customer.note"
      label="Notiz"
      required
    ></v-text-field>
    <v-btn block color="success" @click.prevent="updateCustomer">Speichern</v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'DataTab',

  data: () => ({
    valid: true,
    loading: false,
    customer: {},
    notification: {
      state: false,
      timeout: 2500
    }
  }),

  computed: {
    customerUpdateData: function () {
      return {
        company_vat: this.customer.company_vat,
        customer_number: this.customer.customer_number,
        custom_customer_number: this.customer.custom_customer_number,
        phone: this.customer.phone,
        email: this.customer.email,
        homepage: this.customer.homepage,
        note: this.customer.note
      }
    }
  },

  methods: {
    loadCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.get(`customers/${customerSlug}`)
        .then(response => {
          this.customer = response.data
          this.loading = false
        })
    },
    updateCustomer: function () {
      this.loading = true
      const customerSlug = this.$route.params.slug
      this.$http.patch(`customers/${customerSlug}`, this.customerUpdateData).then(response => {
        this.loading = false
        this.notification.state = true
      })
    }
  },

  mounted () {
    this.loadCustomer()
  }
}
</script>

<style scoped>

</style>
