<template>
  <guest-layout>
    <v-main>
      <v-overlay :value="loading"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>

      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Duesseldorf Eyewear</v-toolbar-title>
              </v-toolbar>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="login">
                  <v-card-text>
                    <validation-provider v-slot="{ errors }" name="Username" rules="required">
                      <v-text-field
                        prepend-icon="mdi-account"
                        name="login"
                        v-model="username"
                        :error-messages="errors"
                        label="Benutzername"
                        type="text"
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider v-slot="{ errors }" name="Password" rules="required">
                      <v-text-field
                        id="password"
                        prepend-icon="mdi-lock"
                        :error-messages="errors"
                        name="password"
                        v-model="password"
                        label="Password"
                        type="password"
                      ></v-text-field>
                    </validation-provider>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :disabled="invalid">Login</v-btn>
                  </v-card-actions>
                </form>
              </validation-observer>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </guest-layout>
</template>

<script>
import GuestLayout from '../components/Layouts/Guest'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('aggressive')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

export default {
  name: 'Login',

  components: {
    GuestLayout,
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    username: '',
    password: ''
  }),

  methods: {
    login () {
      this.$refs.observer.validate()

      this.loading = true

      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
